import dayjs from "dayjs";
import { $host } from "../http";
import { dateFormat } from "../utils/consts";

class TimetableService {
  useStorageTimetableList({ onSuccessful, onError }) {
    const getData = async (sklad_use, start, end, isTemplate, filterInn = []) => {
      const filters = {
        sklad_use,
        start: dayjs(start).format(dateFormat),
        end: dayjs(end).format(dateFormat),
        isTemplate,
        filterInn,
      };

      await $host
        .post("/api/storage_timetable/filter", filters)
        .then((response) => {
          if (response.status !== 200) throw onError(response.status);

          return response.data;
        })
        .then((timetableList) => {
          onSuccessful(timetableList.events, timetableList.resources, timetableList.week);
        })
        .catch((err) => {
          onError(err?.response?.data?.message);
        });
    };

    return {
      update: async (sklad_use, start, end, isTemplate = false, filterInn = []) => {
        await getData(sklad_use, start, end, isTemplate, filterInn);
      },
    };
  }

  wrapperStorageTimetableUpdate({ onSuccessful, onError }) {
    return {
      updateTimetable: async (timetableData) =>
        $host
          .patch("/api/storage_timetable", timetableData)
          .then((response) => {
            if (response.status === 200) {
              onSuccessful();

              return response.data;
            } else {
              throw response.data;
            }
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          }),
    };
  }

  async updateTimetable(timetableData) {
    return $host.patch("/api/storage_timetable", timetableData);
  }

  wrapperStorageTimetableReportList({ onSuccessful, onError }) {
    const getData = async (data) => {
      $host
        .get(`/api/storage_timetable/report/${data?.start}/${data?.end}/${data?.inn}/${data?.state_id}/${data?.status}`)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response.data;
          }
        })
        .then((timetableList) => {
          onSuccessful(timetableList);
        })
        .catch((err) => {
          onError(err?.response?.data?.message);
        });
    };

    return {
      updateTimetableReport: (data) => {
        getData(data);
      },
    };
  }

  wrapperStorageTimetableEdit({ onSuccessful, onError }) {
    return {
      saveTimetable: async (timetableData) =>
        $host
          .put("/api/storage_timetable", timetableData)
          .then((response) => {
            if (response.status === 200) {
              onSuccessful(response.data, timetableData);

              return { responseData: response.data, timetableData };
            } else {
              throw response.data;
            }
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          }),
    };
  }

  wrapperStorageTimetableDelete({ onSuccessful, onError }) {
    return {
      deleteStorageTimetable: (id, comment) =>
        $host
          .delete(`/api/storage_timetable/${id}`, { data: comment })
          .then((res) => {
            if (res.status === 200) {
              onSuccessful();
            } else {
              throw res.data;
            }
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          }),
    };
  }

  wrapperStorageTimetableCreate({ onSuccessful, onError }) {
    return {
      createTimetable: async (data) =>
        $host
          .post("/api/storage_timetable", data)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((data) => {
            onSuccessful(data);

            return data;
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          }),
    };
  }

  async createTimetable(data) {
    return $host.post("/api/storage_timetable", data);
  }

  wrapperStorageTimetableByID({ onSuccessful, onError }) {
    return {
      updateStorage: (id) => {
        $host
          .get(`/api/storage_timetable/get/${id}`)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((storage) => {
            onSuccessful(storage);
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          });
      },
    };
  }

  wrapperCheckingDateIsFree({ onError }) {
    return {
      checkingDateIsFree: async (data) =>
        $host
          .post("/api/storage_timetable/check_date_is_free", data)
          .then((response) => response.data.isFree)
          .catch((err) => {
            onError(err?.response?.data?.message);
          }),
    };
  }

  wrapperApproveList({ onSuccessful, onError }) {
    return {
      getApproveList: (data) => {
        $host
          .post("/api/storage_timetable/approve/", data)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((response) => {
            onSuccessful(response);
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          });
      },
    };
  }

  wrapperCheckTemplateCancel({ onSuccessful, onError }) {
    return {
      getIsCheckTemplateCancel: async (date, innGroup, stateId) =>
        $host
          .get("/api/storage_timetable/check_template_cancel/", { params: { date: String(date), innGroup, stateId } })
          .then((response) => {
            onSuccessful(response.data);

            return response.data;
          })
          .catch((err) => {
            onError(err?.response?.data?.message);

            return {};
          }),
    };
  }

  async getUnloading(warehouse, start, end) {
    return $host.get(`/api/storage_timetable/unloading?warehouse=${warehouse}&start=${start}&end=${end}`);
  }

  async getEventById(id) {
    return $host.get(`/api/storage_timetable/event/${id}`);
  }

  /**
   * Get more info about event
   * @param {String} timetableId
   */
  async moreInfoTimetable(timetableId) {
    return $host.get(`/api/storage_timetable/info/${timetableId}`);
  }

  async removeTimetable(timetableId) {
    return $host.delete(`/api/storage_timetable/remove/${timetableId}`);
  }

  async getBusyTime(data) {
    return $host.post("/api/storage_timetable/get-busy-time", data);
  }

  async checkEventDuration(data) {
    return $host.post("/api/storage_timetable/check-event-duration", data);
  }

  async edit(timetableData) {
    return $host.put("/api/storage_timetable", timetableData);
  }
  async email(data) {
    return $host.post("/api/storage_timetable/send_email", data);
  }

  async getById(id) {
    return $host.get(`/api/storage_timetable/get/${id}`);
  }

  async getActionHistory(data) {
    return $host.post("/api/storage_timetable/action-history", data);
  }
}

export default new TimetableService();
