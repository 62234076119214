// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe#webpack-dev-server-client-overlay {
  display: none !important
}

.imagePdf {
  cursor: pointer;
}

.imagePdf:hover {
  background-color: #aaa;
}

.ant-btn-primary {
  background: #1890E6;
}

* {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}

html {
  overflow-y: hidden;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;;;;;;EAME,aAAa;EACb,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,qBAAqB;EACrB,4BAA4B;AAC9B","sourcesContent":["iframe#webpack-dev-server-client-overlay {\n  display: none !important\n}\n\n.imagePdf {\n  cursor: pointer;\n}\n\n.imagePdf:hover {\n  background-color: #aaa;\n}\n\n.ant-btn-primary {\n  background: #1890E6;\n}\n\n* {\n  padding: 0;\n  margin: 0;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin-top: 0;\n  margin-bottom: 0.5em;\n  font-weight: 500;\n}\n\nhtml {\n  overflow-y: hidden;\n}\n\n.text-overflow {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
